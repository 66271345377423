.MuiFormControl-root.SelectOption, .SelectOption {
  width: 100%;
  padding: 1px 0 0 0;
  margin: 15px 0 0 0;

  .MuiFormLabel-filled, .Mui-focused {
    background-color: white;
    border-left: 6px solid white;
    margin-left: -5px;
    border-right: 6px solid white;
  }
}

.SwitchOption {
  margin: 0 0 0 8px !important;
}

//.SwitchOptionLabel {
//    margin-top: 40px !important;
//}
//.CalendarWidthFull {
//    width: 100% !important;
//    padding: 8px !important;
//    margin: 15px 0 0 0 !important;
//}

.rdw-editor-main {
  min-height: 200px !important;
  box-sizing: border-box !important;
  border: 1px solid #F1F1F1 !important;
  overflow: auto !important;
  padding: 10px !important;
}

.rdw-editor-wrapper {
  box-sizing: content-box !important;
  margin-top: 20px !important;
}
